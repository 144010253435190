import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import {dbCollections} from "@/utils/firebaseCollection"
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'field_name', sortable: true },
    { key: 'no_of_course',label:'No. of Course', sortable: true },
    { key: 'actions' }
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const isSpinner = ref(null)
  const allRecords = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  
  watch([currentPage, perPage, searchQuery, roleFilter, planFilter], () => {
    refetchData()
  })
  
  var usersList = []
  var users = []
  isSpinner.value=true
  const fetchUsers = (ctx, callback) => {
    users = []
    console.log("get List")
    if(usersList.length==0 && isSpinner.value)
    {
      db
        .collection(dbCollections.FIELDANDCOURSE)
        .orderBy('createdAt')
        .get()
        .then(querySnapshot=>{
          if(!querySnapshot.empty)
          {
            var startIndex = (currentPage.value-1)*perPage.value+1
            var endIndex = startIndex+perPage.value-1
            var index = 1
            var dataIndex = 1
            console.log(startIndex,endIndex)
            querySnapshot.forEach(doc=>{

              //Turn isDeleted Flag = false
              // db
              //   .collection(dbCollections.FIELDANDCOURSE)
              //   .doc(doc.id)
              //   .update({
              //     isDeleted:false
              //   })
              //   .catch(error => console.log(error))

              // if(!doc.data().isDeleted)
              // {
                usersList.push(doc.data())

                usersList[usersList.length-1].field_name=doc.data().fieldName
                usersList[usersList.length-1].no_of_course=doc.data().courses.length
              // }
              if(dataIndex>=startIndex && dataIndex<=endIndex)
              {
                users.push({
                  'id':doc.data().id,
                  'isDeleted':doc.data().isDeleted||false,

                  'field_name':doc.data().fieldName,
                  'no_of_course':doc.data().courses
                })
                dataIndex++
              }
              if(index==querySnapshot.size)
              {
                totalUsers.value = usersList.length
                isSpinner.value=false
                allRecords.value = usersList;
                callback(users)
              }
              index++;
            })
          }
          else
          {
            totalUsers.value = querySnapshot.size
            isSpinner.value=false
            allRecords.value = usersList;
            callback(users)
          }
        })
        .catch(error => {
          isSpinner.value=false
          console.log(error)
        })
    }
    else
    {
      console.log("Already has list data")
      var startIndex = (currentPage.value-1)*perPage.value+1
      var endIndex = startIndex+perPage.value-1
      var index = 1
      var totalRecords = 0
      // var dataIndex = 1
      console.log(startIndex,endIndex)
      if(usersList.length==0)
      {
        totalUsers.value = usersList.length
        isSpinner.value=false
        allRecords.value = usersList;
        callback(users)
        return;
      }

      if(isSortDirDesc.value==false)
      {
        console.log("Asc")
        usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
      }
      else if(isSortDirDesc.value==true)
      {
        console.log("Desc")
        usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
      }
      
      usersList.forEach(data=>{
        // console.log("Data",data)
        if(searchQuery.value=='')
        {
          if(index>=startIndex && index<=endIndex)
          {
            users.push({
              'id':data.id,
              'isDeleted':data.isDeleted||false,
              'field_name':data.fieldName,
              'no_of_course':data.courses
            })
          }
          if(index==usersList.length)
          {
            totalUsers.value = usersList.length
            isSpinner.value=false
            allRecords.value = usersList;
            callback(users)
          }
          index++;
        }
        else if(searchQuery.value!='')
        {
          if(
            data.fieldName.toLowerCase().includes(searchQuery.value.toLowerCase())
          )
          {
            users.push({
              'id':data.id,
              'isDeleted':data.isDeleted||false,
              'field_name':data.fieldName,
              'no_of_course':data.courses
            })
            totalRecords++
          }
          if(index==usersList.length)
          {
            // if(currentPage.value > 1 && users.length==0)
            // {
            //   currentPage.value -= currentPage.value
            // }
            var tmp = []
            var dataIndex = 1

            users.forEach(FD=>{
              if(dataIndex>=startIndex && dataIndex<=endIndex)
              {
                tmp.push(FD)
              }
              dataIndex++
            })

            totalUsers.value = users.length
            isSpinner.value=false
            callback(tmp)

            // totalUsers.value = totalRecords
            // isSpinner.value=false
            allRecords.value = usersList;
            // callback(users)
          }
          index++;
        }
        else
        {
          isSpinner.value=false
        }
      })
    }
  }
  const refreshPage = (callback)=>{
    isSpinner.value=true
    usersList=[]
    refetchData()
    callback(true)
  }

  function deleteFieldCourse(data, deletedObj,cb){   
    if(data.courses.length > 0){
      let searchInd = data.courses.findIndex(elem=>{
        return elem.fieldName.id == deletedObj.id;
      })
      if(searchInd > -1){
        cb(true)
      }else{
        cb(false)
      }     
    }else{
      cb(false)
    }
  }

  const deleteField = (ctx, callback)=>{    
      if(ctx.isDisbled == true) {
        var index = (currentPage.value-1) * perPage.value + ctx.index + 1;
        db.collection(dbCollections.FIELDANDCOURSE).doc(ctx.item.id).update({
          isDeleted:!ctx.item.isDeleted
        })
        .then(()=>{
          if(ctx.item.isDeleted == true) {
            callback({'msg':'enabled Successfully',opt:true})
          } else {
            callback({'msg':'disabled Successfully',opt:true})
          }
          usersList.splice(index-1,1,{...usersList[index-1],'isDeleted':!ctx.item.isDeleted})
          refetchData()
        })
        .catch(error=>{
          callback({'msg':error,opt:false})
          console.log(error)
        })
      } else {
        db
        .collection(dbCollections.COLLEGES)
        .orderBy('createdAt')
        .get()
        .then(querySnapshot=>{
          let fileArry = [];
          if(!querySnapshot.empty)
          {
            querySnapshot.forEach(val=>{
              fileArry.push(val.data());
            })
            let count = 0;          
            const uploadFileFunction = (row) => {
                if(count >= fileArry.length){       
                      var index = (currentPage.value-1) * perPage.value + ctx.index + 1;
                      db.collection(dbCollections.FIELDANDCOURSE).doc(ctx.item.id).delete()
                      .then(()=>{
                        callback({'msg':'deleted Successfully',opt:true})
                        usersList.splice(index-1,1)
                        refetchData()
                      })
                      .catch(error=>{
                        callback({'msg':error,opt:false})
                        console.log(error)
                      })
                      return;            
                }else{
                deleteFieldCourse(row,ctx.item,(saveRes)=>{
                    if(saveRes == true){
                      callback({'msg':'These field used in another documents.',opt:false})
                      return;
                    }
                    if(saveRes == false){
                    count += 1;
                    uploadFileFunction(fileArry[count]);
                    }
                });
              }              
            }
            uploadFileFunction(fileArry[count]);
          }else{
            var index = (currentPage.value-1) * perPage.value + ctx.index + 1;
            db.collection(dbCollections.FIELDANDCOURSE).doc(ctx.item.id).delete()
            .then(()=>{
              callback({'msg':'deleted Successfully',opt:true})
              usersList.splice(index-1,1)
              refetchData()
            })
            .catch(error=>{
              callback({'msg':error,opt:false})
              console.log(error)
            })
          }
        })
      }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    deleteField,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    isSpinner,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    refreshPage,
    allRecords
  }
}

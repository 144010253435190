<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">


      <!-- <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      /> -->

      <!-- Filters -->
      <!-- <users-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
      /> -->

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <!-- <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button> -->
              </div>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative fieldcourse_mgmt_wrapper"
          :items="fetchUsers"
          responsive
          v-model='table_listing'
          :fields="tableColumns"
          primary-key="id"
          :show-empty="!isSpinner"
          hover
          empty-text="No matching records found"
          @row-clicked="handleRowClick"
          :sort-by.sync="sortBy"
          :tbody-tr-class="rowClass"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: No. -->
          <template #cell(no)="data">
            <div>
                <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <!-- Column: No Of Courses -->
          <template #cell(no_of_course)="data">
            <div style="white-space: nowrap">
              {{data.item.no_of_course.length}}
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div style="white-space: nowrap;display:flex;justify-content: end;align-items: center;" v-if="$route.meta.action=='write' || $route.meta.action==undefined" @click.stop.prevent>
              <img src='@/assets/images/erflog/Edit.png' v-if="!data.item.isDeleted" @click.prevent="$router.push({name:'edit-field-course',params:{ id: data.item.id,name:data.item.field_name }})" class="action_icon"/>
              <img src='@/assets/images/erflog/Delete.png' style="cursor:pointer" @click.prevent="selectedField={...data,isDisbled:false}" v-b-modal.delete-field class="action_icon"/>
              <!-- <img src='@/assets/images/erflog/enable_new.png' v-if="!data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedField={...data,isDisbled:true}" v-b-modal.delete-field class="action_icon"/>
              <img src='@/assets/images/erflog/disable_new.png' v-if="data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedField={...data,isDisbled:true}" v-b-modal.delete-field class="action_icon"/> -->
              <b-icon-toggle-on class="action_icon focusRemoveToggle" variant="success" width="30px" height="22px" v-if="!data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedField={...data,isDisbled:true}" v-b-modal.delete-field></b-icon-toggle-on>
              <b-icon-toggle-off class="action_icon focusRemoveToggle" variant="secondary" width="30px" height="22px" v-if="data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedField={...data,isDisbled:true}" v-b-modal.delete-field></b-icon-toggle-off>
            </div>
            <div v-else>
              --
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <b-modal
        id="delete-field"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedField=''"
        v-if="selectedField!=''"
        @ok="deleteFieldFunction(selectedField)"
      >
      <div class="create_account_img">
        <img src="@/assets/images/erflog/Approve_Popup.png" alt="">
      </div>
        <div style="text-align: center;" v-if="selectedField!=''">
          <label class="s_modal_title" v-if="selectedField.item.isDeleted == false && selectedField.isDisbled == true">Disable Field</label>
          <label class="s_modal_title" v-if="selectedField.item.isDeleted == true && selectedField.isDisbled == true">Enable Field</label>
          <label class="s_modal_title" v-if="selectedField.isDisbled == false">Delete Field</label>
          <p v-if="selectedField.item.isDeleted == false && selectedField.isDisbled == true">Are you sure you want to disable {{selectedField.item.field_name}}?</p>
          <p v-if="selectedField.item.isDeleted == true && selectedField.isDisbled == true">Are you sure you want to enable {{selectedField.item.field_name}}?</p>
          <p v-if="selectedField.isDisbled == false">Are you sure you want to delete {{selectedField.item.field_name}}?</p>
        </div>
      </b-modal>
      <importModel 
      :openImportMOdel='openImportMOdel' 
      :modelTitle="modelTitle" 
      :fileName='fileName' 
      :row='row'
      :ReportTitle='ReportTitle'
      @closeImportCsvModel='closeModel'
      @importCsvFile='importCsvFile'
      :fieldError='fieldError'
      :isSppiner='isSppiner'
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,
  BForm,BFormTextarea,BFormSelect,BSpinner,BIconToggleOff,BIconToggleOn
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useFieldCourseList'
//vee-validator

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'
import { importCSVData } from '@/utils/importExportFiles/importFunction.js'
const db = firebase.firestore()
import firebaseTimeStamp from "firebase";
import Ripple from 'vue-ripple-directive'
import importModel from '@/views/components/importModel.vue'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import * as insertQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormRatingBorderlessVue from '@/views/forms/form-element/form-rating/FormRatingBorderless.vue'
export default {
  directives: {
    Ripple,
  },
  components: {
    // UsersListFilters,
    // UserListAddNew,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BFormSelect,
    vSelect,
    importModel,
    BSpinner,
    BIconToggleOn,
    BIconToggleOff,
},
  data(){
    return{
      selectedField:"",
      openImportMOdel:false,
      modelTitle:'',
      fileName:'',
      csvHeader: {
        'fieldName' : "MscIt",
        'courceName' : "operating-Sytem"        
      },
      ReportTitle:['FieldName','CourceName'],
      row:'',
      fieldError:'',
      isSppiner:false,
      allFields:[],
      table_listing:[],
      skipFieldsColumn:[]
    }
  },
  created() {
    var self = this;
    self.getAllFieldAndCourse(res=>{
    });
    // getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDANDCOURSE,'isDeleted','==',false,res=>{
    //   self.allFields = []; 
    //   if(res){
    //     self.allFields =res;
    //   }
    // })

    this.$root.$on('importFieldCourse',()=>{
      this.row='';
      this.fieldError = '';      
      self.modelTitle="Field & Course Managment List"
      self.fileName='FieldAndCource-Details';       
      this.row = [
        ['FieldName','CourceName'],
        ['MscIt','operating-Sytem'],
        ['BCS','Sytem-Software']
      ];      
      this.row = JSON.stringify(this.row)       
      self.openImportMOdel = true;
    })

    // EXPORT DISPLAYED LIST OF FIELD AND COURCE MANAGEMENT
    this.$root.$on('exportFieldAndCourse',()=>{
      var array = [
      ['FieldName','CourceName']
      ];
      console.log(this.allRecords,"this.allRecords")
      if(this.allRecords.length > 0){
        this.allRecords.forEach((values,index)=>{
          console.log(values,"?????")
          if(Number(index) > this.allRecords.length){
            return
          }else{
              if(values){
                if(values.courses.length > 0){
                  for(let t = 0; t < values.courses.length; t++){
                    if(t <= values.courses.length){
                      let tempFieldArray = [JSON.stringify(values.fieldName),JSON.stringify(values.courses[t].name)]
                      array.push(tempFieldArray)
                    }else{
                      break;
                    }
                  }
                }
              }
          }         
        })
        this.exportFieldAndCourseFile(array,res=>{
          console.log(res,"DONE")
          setTimeout(()=>{
            this.$root.$emit('stopSpinner')
          },1000)          
        })
      }else{
          this.$root.$emit('showToastMessage','No record found','danger')
            setTimeout(()=>{
            this.$root.$emit('stopSpinner')
          },1000) 
      }      
    })
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      isSpinner,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      refreshPage,
      allRecords,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      isSpinner,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      refreshPage,
      allRecords
    }
  },
  methods:{
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.isDeleted === true) return 'table-disbled-row'
    },
    exportFieldAndCourseFile(array,cb){
      var str = '';
      var filename = 'Field & Course Managment List';
      for (var i = 0; i < array.length; i++) { 
      let line = '';
      line =array[i].join(',');
      str += line + '\r\n';
      }
      var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });

      var link = document.createElement('a');
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      cb(true);
    },
    getAllFieldAndCourse(cb){
       var self = this;
      getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDANDCOURSE,'isDeleted','==',false,res=>{
        self.allFields = []; 
        if(res){
          self.allFields =res;
          cb(res);
          return;
        }
      })
    },
    deleteFieldFunction(data){
      this.deleteField(data,res=>{
        console.log(res)
        if(res.opt == true){
          this.$root.$emit('showToastMessage',res.msg,'success')
        }else{
          this.$root.$emit('showToastMessage',res.msg,'danger')
        }
      })
    },
    handleRowClick(data)
    {
      if(!data.isDeleted) {
        this.$router.push({name:'field-course-info',params: { id: data.id,name:data.field_name }})
      }
    },
    isOnlyDecimal(event) {
      ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
      ValidationFunction.OnlyIntegerNumber(event);
    },
    getIntakeNo(cb)
    {
        var self = this
        db
          .collection(dbCollections.USERS)
          .orderBy('createdAt')
          .get()
          .then((querySnapshot)=>{
              var newIntakeNo=0
              if(!querySnapshot.empty)
              {
                  querySnapshot.forEach(doc=>{
                      if(!doc.data().isDeleted && doc.data().role=="student")
                      {
                        var uniqueId = doc.data().uniqueId.split('/')
                        if(uniqueId[0]==self.selectedUser.item.docData.intake && uniqueId[1]==new Date().getFullYear() && uniqueId[2]>=newIntakeNo)
                        {
                            newIntakeNo = parseInt(uniqueId[2])+1
                        }
                        var zeroes = 3-newIntakeNo.toString().length

                        for (let i = 0; i < zeroes; i++) {
                          newIntakeNo='0'+newIntakeNo
                        }
                        console.log("last No",newIntakeNo,uniqueId,doc.data().uniqueId)
                      }
                  })
                  if(newIntakeNo==0)
                  {
                    newIntakeNo='001'
                  }
              }
              else
              {
                newIntakeNo='001'
              }
              cb(newIntakeNo)
          })
    },
    closeModel(event){
      this.openImportMOdel = event     
    },
    // FUN 1..
    importCsvFile(event){  
      if(event.length > 0){
        console.log(event,"event")
        console.log(event.length,"length *****")
        this.skipFieldsColumn = [];
        this.isSppiner = true;
          let count = 0;
            const uploadFileFunction = (row) => {  
                this.uploadImportFile(row,(saveRes)=>{                   
                  console.log('saveRes', saveRes.length)
                  // return
                    if(saveRes == false)
                    {
                      this.$root.$emit('errorUploadAgain')
                      this.isSppiner = false;
                      return;
                    }
                    else{                     
                          if(count >= parseInt(Number(event.length)-1))
                          {     
                                if(saveRes.length > 0){
                                  let saveObject = [];                                
                                        if(saveRes.length > 0){              
                                          saveRes.forEach((ele,saveResIndex)=>{ 
                                              if(this.allFields.length > 0){
                                                let searchIndex = this.allFields.findIndex(allelem=>{
                                                  console.log(allelem,"allelem")
                                                  console.log(ele,"ELE")
                                                  return allelem.fieldName.toLowerCase().trim() == ele.FieldName.toLowerCase().trim()
                                                })
                                                if(searchIndex > -1){
                                                    if(this.allFields[searchIndex].courses.length > 0){
                                                      //check same field name's course
                                                        let searchCourseIndex = this.allFields[searchIndex].courses.findIndex(elem_course=>{
                                                          return elem_course.name.toLowerCase().trim() == ele.CourceName.toLowerCase().trim()
                                                        })
                                                        if(searchCourseIndex > -1){
                                                          this.skipFieldsColumn.push({fieldName:ele,index:Number(saveResIndex + 2)}) //store list of skip field columns
                                                        }else{ 
                                                          if(saveObject.length > 0){
                                                              let searchInd = saveObject.findIndex(vals=>{
                                                                return vals.ele.CourceName.toLowerCase().trim() == ele.CourceName.toLowerCase().trim() && vals.ele.FieldName.toLowerCase().trim() == ele.FieldName.toLowerCase().trim()
                                                              })
                                                              if(searchInd <= -1){
                                                                saveObject.push({ele}) 
                                                              }
                                                              else{
                                                                this.skipFieldsColumn.push({fieldName:ele,index:Number(saveResIndex + 2)}) //store list of skip field columns
                                                              }
                                                          }else{                                            
                                                          saveObject.push({ele}) 
                                                          }
                                                        }
                                                    }
                                                }else{
                                                  if(saveObject.length > 0){
                                                    let searchInd = saveObject.findIndex(vals=>{
                                                      return vals.ele.CourceName.toLowerCase().trim() == ele.CourceName.toLowerCase().trim() && vals.ele.FieldName.toLowerCase().trim() == ele.FieldName.toLowerCase().trim()
                                                    })
                                                    if(searchInd <= -1){
                                                      saveObject.push({ele}) 
                                                    }else{
                                                      this.skipFieldsColumn.push({fieldName:ele,index:Number(saveResIndex + 2)}) //store list of skip field columns
                                                    }
                                                  }else{  
                                                  saveObject.push({ele}) 
                                                  }
                                                }
                                              }else{
                                                if(saveObject.length > 0){
                                                  let searchInd = saveObject.findIndex(vals=>{
                                                    return vals.ele.CourceName.toLowerCase().trim() == ele.CourceName.toLowerCase().trim() && vals.ele.FieldName.toLowerCase().trim() == ele.FieldName.toLowerCase().trim()
                                                  })
                                                  if(searchInd <= -1){
                                                    saveObject.push({ele}) 
                                                  }else{
                                                    this.skipFieldsColumn.push({fieldName:ele,index:Number(saveResIndex + 2)}) //store list of skip field columns
                                                  }
                                                }else{  
                                                saveObject.push({ele}) 
                                                }
                                              }
                                          })
                                        }  
                                        this.isSppiner = false;
                                        if(saveObject.length > 0){  
                                          console.log(saveObject,"Main Res**")

                                        this.saveToCollection(saveObject)
                                        return;
                                        }else{  
                                          this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: 'All fields and courses are alredy exist.',
                                                icon: 'CoffeeIcon',
                                                variant: 'success',                               
                                            },
                                          })
                                          this.getAllFieldAndCourse(res=>{
                                            console.log(res)
                                          });
                                          this.closeModel(false)
                                          this.isSppiner = false;
                                          return;   
                                        }          
                                }
                                else
                                {
                                  count += 1;
                                  uploadFileFunction(event[count]);
                                }
                          }else{
                            count += 1;
                            uploadFileFunction(event[count]);
                          }
                    }
                });              
            }
            uploadFileFunction(event[count]);
      }     
    },
    //AFTER FUN 1 CHECK VALIDATION
    uploadImportFile(data,cb){
      var self = this;
      let fileObject = data; 
      if(fileObject !== undefined){
       importCSVData(fileObject, (res) => {
          if(res.length > 0){
            let isUpload = true;    
            var element = {}; 
            //check rule of excel data....
            for (let i = 0; i < res.length; i++) { 
              element = res[i];   
              var keys = Object.keys(element);
               if(keys[0] == ""){
                self.fieldError = "column name is required, try again[ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                cb(false)
                this.$root.$emit('errorUploadAgain')
                isUpload = false;
                break;
              }
               if(keys[1] == ""){
                self.fieldError = "column name is required, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                cb(false)
                isUpload = false;
                break;
              }
              if(keys[0] != "FieldName" || keys[1] != "CourceName"){
                self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                cb(false)
                this.$root.$emit('errorUploadAgain')
                isUpload = false;
                break;
              }       
              if(element.FieldName == "" || element.CourceName == ""){
                self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                cb(false)
                this.$root.$emit('errorUploadAgain')
                isUpload = false;
                break;
              } 
              if(element.FieldName == undefined || element.CourceName == undefined){
                self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                cb(false)
                this.$root.$emit('errorUploadAgain')
                isUpload = false;
                break;
              }  
            }
            if(isUpload){              
              cb(res)
            }
          }
          else {
            self.fieldError = "Try Again,Empty file can not be allowed (Note - Don't upload download-sample file direct first save as csv[comma delimited] )";      
            this.isSppiner = false;
            this.$root.$emit('errorUploadAgain')
            cb(false);
            return;
          }
      })    
     } // cb(true);    
    },
    importUpdateField(data,isEdit,cb){
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      var resultStr = ""
      for ( var t = 0; t < 10; t++ ) {
          resultStr += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      isEdit.obj.courses.push({name:data.ele.CourceName,id:resultStr})

      let updateObj = {
        'courses': isEdit.obj.courses
      }
      let saveObj = isEdit.obj
      console.log(updateObj,"updateObj",isEdit.obj)
      updateQuery.updateRootCollectionDataByDocId(dbCollections.FIELDANDCOURSE,isEdit.obj.id,updateObj,updateres=>{                    
          console.log(updateres,"jyoti yadav*****")  
          this.allFields.push(saveObj)      
          cb(updateObj)                           
      }) 
    },
    importNewField(data,cb){
      let allcourses= []             
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      var resultStr = ""
      for ( var i = 0; i < 10; i++ ) {
          resultStr += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      allcourses.push({'name':data.ele.CourceName,'id':resultStr})   
  
      var saveObj = {
        createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        isDeleted:false,
        id:"",
        fieldName:data.ele.FieldName,
        courses:allcourses
      }
            
      insertQuery.addDataToRootCollection(dbCollections.FIELDANDCOURSE,saveObj,res=>{
        let updateId = {
          'id':res.id
        }
        updateQuery.updateRootCollectionDataByDocId(dbCollections.FIELDANDCOURSE,res.id,updateId,updateres=>{
          console.log(updateres,'100 000 000 Done') 
          // addedData.push(data)
          saveObj.id = res.id;
          this.allFields.push(saveObj)
          cb(saveObj)                      
        })           
      })       
    },
    eachFieldCourseData(element,data,cb){      
        if(element){
          if(element.fieldName.toLowerCase().trim() == data.ele.FieldName.toLowerCase().trim()){
            cb({'check':true,
            'obj':element})
          }else{
            cb(null)
          }    
        }
    },
    //FUN 2.1...
    saveImportCsvData(data,cb){
      var self = this; 
      console.log(data,"data")    
            if(data !== undefined){ 
              let addedData = []; 
              if(this.allFields.length > 0){ //Collection Array
                // let count = 0;
                let fileArry = this.allFields; 
                console.log(fileArry,"fileArryfileArry")            
                let isEdit = {
                  'check':false,
                  'obj':null
                };          
                let count = 0;                
                const uploadFileFunction = (row) => {
                  console.log(row)
                  console.log(count ,">=", fileArry.length)
                  if(count >= fileArry.length){    
                     if(isEdit.check == true){ //UPDATE EXISTING
                      this.importUpdateField(data,isEdit,updatedRes=>{
                         cb(updatedRes)                           
                      }) 
                    }
                    else{  //CREATE NEW 
                      if(isEdit.check == false){     
                        console.log("CALLED CREATE NEW") 
                        this.importNewField(data,createRes=>{
                          cb(createRes)     
                        }) 
                      }
                    }    
                    return        
                  }else{
                    console.log("ins")
                    this.eachFieldCourseData(row,data,(saveRes)=>{
                        if(saveRes !== null){
                        isEdit = saveRes;
                        }
                        count += 1;
                        uploadFileFunction(fileArry[count]);
                    });
                  }              
                }
                uploadFileFunction(fileArry[count]);
              }
              //if FIELD AND COURSE IS FIRST ENTRY
              else{
                this.importNewField(data,createRes=>{
                   cb(createRes)     
                })                
              }
          }
          else{
            cb(false)
          }       
 },
    // FUN 1.2..
    saveToCollection(data){
      var self = this;
      this.isSppiner = true;
      if(data.length > 0){
        let count = 0;
        const uploadFileFunction = (row) => {
            self.saveImportCsvData(row,(saveRes)=>{  
                if(count > data.length){                 
                   this.isSppiner = false;
                   if(this.skipFieldsColumn.length > 0){                            
                            let skipStr = "";
                            let popText = ""
                            this.skipFieldsColumn.forEach(elem=>{
                              if(skipStr !== ""){
                              skipStr = skipStr +","+ '['+ elem.index+ '. ' + elem.fieldName.FieldName + ']';
                              }else{
                              skipStr += '['+ elem.index +'. '+ elem.fieldName.FieldName + ']';
                              }
                            })
                            popText = "column-values are skip"+ " "+skipStr+" ";
                            console.log(popText,"************************************")
                            this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                  title: 'Document Added Successfully', 
                                  icon: 'CoffeeIcon',
                                  variant: 'success',                               
                              },
                            })
                            this.refreshPage(res=>{
                              console.log(res,saveRes)
                            });
                            self.getAllFieldAndCourse(res=>{
                              console.log(res)
                            });
                            this.$root.$emit('importFileDone')
                            this.closeModel(false)
                            return;
                   }else{
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                              title: 'Document Added Successfully',
                              icon: 'CoffeeIcon',
                              variant: 'success',                               
                          },
                        })
                        this.refreshPage(res=>{
                          console.log(res,saveRes)
                        });
                        self.getAllFieldAndCourse(res=>{
                          console.log(res)
                        });
                        this.$root.$emit('importFileDone')
                        this.closeModel(false)
                        return;
                  }                  
                }else{              
                  count += 1;
                  uploadFileFunction(data[count]);               
                }
            });              
        }
        uploadFileFunction(data[count]);
      }else{
        this.isSppiner = false;
      }
      // if()
    }
  },
  beforeDestroy(){
    this.$root.$off('exportFieldAndCourse')
  },
}
</script>
<style>
.focusRemoveToggle:focus {
  outline: none !important;
}
.table-disbled-row {
  cursor: default !important;
}
</style>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
